import React, { Component } from "react";
import UrlTranslator from "../utils/UrlTranslator";
import Navbar from "./NavBar.jsx";

class Navigation extends Component {
  constructor(props) {
    super(props);
    let { page } = UrlTranslator.getPageFromUrl(window.location.pathname, {
      changePage: this.changePage,
    });
    this.state = {
      currentPage: page,
    };
    window.history.replaceState(
      this.state.currentPage.url,
      null,
      this.state.currentPage.url
    );
  }

  changePage = (pageUrl) => {
    let { title, page } = UrlTranslator.getPageFromUrl(pageUrl, {
      changePage: this.changePage,
    });
    this.setState({ currentPage: page });
    window.history.pushState(pageUrl, title, pageUrl);
  };

  render() {
    return (
      <div className="App">
        <Navbar />
        <div>{this.state.currentPage}</div>
      </div>
    );
  }
}

export default Navigation;
