import { Menu, Modal } from "antd";
import React, { Component } from "react";
import Profile from "../components/Profile.jsx";
import Scoring from "../components/Scoring.jsx";
import { QuestionCircleOutlined } from "@ant-design/icons";
import LongerBackground from "../images/LongerBackground.png";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isDrawerVisible: false,
      isScoringVisible: false,
    };
  }

  handleScoring = () => {
    this.setState({ isScoringVisible: true });
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    this.setState({ isModalVisible: false });
    this.setState({ isDrawerVisible: false });
    this.setState({ isScoringVisible: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
    this.setState({ isDrawerVisible: false });
    this.setState({ isScoringVisible: false });
  };

  render() {
    return (
      <div className="App">
        <Modal
          title="Statistics"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          visible={this.state.isDrawerVisible}
          destroyOnClose={true}
          footer={null}
          style={{
            zIndex: "9999999",
          }}
        >
          <Profile />
        </Modal>
        <Modal
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          visible={this.state.isScoringVisible}
          destroyOnClose={true}
          footer={null}
          style={{
            zIndex: "9999999",
          }}
        >
          <Scoring />
        </Modal>
        <img className="four-sigma-background" src={LongerBackground}></img>
        <Menu mode="horizontal" style={{ padding: "1%", zIndex: "100" }} className="page-header">
          <Menu.Item
            style={{
              float: "left",
              fontSize: "150%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              borderBottom: "none",
            }}
          >
            <Menu.Item key="home" style={{ fontSize: "150%", zIndex: "100", position: "relative" }}>
              4-σ
            </Menu.Item>
          </Menu.Item>
          {/* <Menu.SubMenu title="$" style={{
            float: "right",
            fontSize: "200%",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            borderBottom: 'none',
            color: "white",
          }}
          >
            <Menu.Item style={{ borderBottom: 'none' }}>
              <a href="https://www.paypal.com/donate/?hosted_button_id=P48R9JDD4T3YE" target="_blank"
                rel="noopener noreferrer">Donate</a>
            </Menu.Item>
          </Menu.SubMenu> */}
          <Menu.Item
            style={{
              float: "right",
              fontSize: "150%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              borderBottom: "none",
              position: "relative"
            }}
          >
            <Menu.Item
              onClick={this.handleScoring}
              key="scoring"
              style={{ marginTop: "20%", borderBottom: "none", float: "right", position: "relative", zIndex: "100" }}
            >
              <QuestionCircleOutlined style={{ fontSize: "125%" }} />
            </Menu.Item>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default NavBar;
