import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./components/App.jsx";
import reportWebVitals from "./reportWebVitals";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import dark from "../src/styles/dark-theme.css";

const theme = {
  dark: dark,
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeSwitcherProvider
      themeMap={theme}
      defaultTheme="dark"
    ></ThemeSwitcherProvider>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
