import { Button, InputNumber, Form, Menu } from "antd";
import React, { Component } from "react";
import Score from "../service/Score.js";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import firebase from "firebase/app";
import { app } from "../service/firebase";

var db = firebase.firestore(app);
var moment = require("moment-timezone");
var genesis = moment("08/15/2022");

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionNumber: 1,
      data: [],
      engagement: 0,
      disabled: false
    };
    this.setQuestionData = this.setQuestionData.bind(this);
    this.setEngagementData = this.setEngagementData.bind(this);
  }
  formRef = React.createRef();

  onFinish = async (values) => {
    this.setState({ disabled: true });
    let lower = parseFloat(values.lowerBound);
    let upper = parseFloat(values.upperBound);
    let answer = this.state.data[this.state.questionNumber].answer;
    if (upper < lower) {
      alert("Upper Bound must be a larger number.");
    } else {
      if (this.size(this.state.data) >= this.state.questionNumber) {
        let score = Score.calculateScore(lower, upper, answer);
        let tempQuestionNumber = this.state.questionNumber;
        let tempQuestion =
          '"' + this.state.data[this.state.questionNumber].question + '"';
        let tempLink =
          '"' + this.state.data[this.state.questionNumber].link + '"';
        let tempReference =
          '"' + this.state.data[this.state.questionNumber].reference + '"';
        let summary;
        let references;
        let correct = 1;
        if (score === -1) {
          score = 0;
          correct = 0;
        }
        if (tempQuestionNumber > 1) {
          summary = [
            localStorage.getItem("summary"),
            tempQuestionNumber,
            tempQuestion,
            lower,
            upper,
            answer,
            score,
            correct,
            tempLink,
          ];
          references = [localStorage.getItem("references"), tempReference];
        } else {
          summary = [
            tempQuestionNumber,
            tempQuestion,
            lower,
            upper,
            answer,
            score,
            correct,
            tempLink,
          ];
          references = [tempReference];
        }
        if (3 === this.state.questionNumber) {
          localStorage.setItem("summary", summary);
          localStorage.setItem("references", references);
          Score.setScore(Score.getScore() + score);
          if (this.state.engagement === undefined) {
            await this.addEngagement();
          } else {
            let count = this.state.engagement;
            await this.updateEngagement(count.completions + 1);
          }
          window.location.href = "/gameover";
        } else {
          localStorage.setItem("summary", summary);
          localStorage.setItem("references", references);
          this.setState({ questionNumber: tempQuestionNumber + 1 });
          Score.setScore(Score.getScore() + score);
          this.formRef.current.resetFields();
          this.forceUpdate();
          this.setState({ disabled: false });
        }
      } else {
        window.location.href = "/gameover";
      }
    }
  };

  addEngagement = async () => {
    let day = localStorage.getItem("day");
    await db.collection("engagement").doc(day).set({
      lowest: 30,
      highest: 0,
      completions: 1,
    });
  };

  updateEngagement = async (count) => {
    let day = localStorage.getItem("day");
    await db.collection("engagement").doc(day).update({
      completions: count,
    });
  };

  componentDidMount = async () => {
    let PT = moment().tz("America/Los_Angeles");
    var days = PT.diff(genesis, "days") + 1;
    if (
      JSON.parse(localStorage.getItem("status")) === "complete" &&
      JSON.parse(localStorage.getItem("day")) === days
    ) {
      window.location.href = "/gameover";
    } else {
      localStorage.setItem("status", JSON.stringify("in_progress"));
      localStorage.setItem("day", days);
      this.setQuestionData(await this.getDataFromDatabase("questions"));
      this.setEngagementData(await this.getDataFromDatabase("engagement"));
    }
  };

  size(obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  }

  setQuestionData(result) {
    this.setState({ data: result });
  }

  setEngagementData(result) {
    this.setState({ engagement: result });
  }

  getDataFromDatabase = async (collection) => {
    let dataFromFirestore;
    let day = localStorage.getItem("day");
    var docRef = db.collection(collection).doc(day);
    await docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          dataFromFirestore = doc.data();
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
    return dataFromFirestore;
  };

  clickBackButton() {
    window.location.href = "/";
    localStorage.removeItem("summary");
  }

  render() {
    return (
      <div className="App">
        <Menu>
          <Menu.Item
            style={{ marginTop: "-70px", float: "right", fontSize: "200%" }}
            disabled={true}
          ></Menu.Item>
        </Menu>
        <div style={{ padding: "1%" }}>
          {this.size(this.state.data) > 0 ? (
            <h2 className="App question">
              {this.state.data[this.state.questionNumber].question}
            </h2>
          ) : null}
          <Form
            className="question-form"
            initialValues={{ remember: false }}
            onFinish={this.onFinish}
            ref={this.formRef}
            disabled={this.state.disabled}
          >
            <Form.Item>
              <Form.Item
                name="lowerBound"
                rules={[{ required: true, message: "Please input a value" }]}
                style={{
                  display: "inline-block",
                  color: "white",
                }}
                className="question-form"
              >
                <InputNumber
                  placeholder="Lower Bound"
                  autoComplete="off"
                  type="text"
                  inputmode="decimal"
                  formatter={(value) =>
                    `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
                  }
                  autoFocus="true"
                  style={{
                    fontSize: "150%",
                    width: "70%",
                    textAlign: "center",
                  }}
                  min={0}
                  className="question-form-text"
                />
              </Form.Item>
              <Form.Item
                name="upperBound"
                rules={[{ required: true, message: "Please input a value" }]}
                style={{ display: "inline-block" }}
                className="question-form"
              >
                <InputNumber
                  placeholder="Upper Bound"
                  autoComplete="off"
                  type="text"
                  inputmode="decimal"
                  formatter={(value) =>
                    `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
                  }
                  style={{
                    fontSize: "150%",
                    width: "70%",
                    padding: "-10%",
                    color: "white",
                    textAlign: "center",
                  }}
                  min={0}
                  className="question-form-text"
                />
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="question-form-button"
                disabled={this.state.disabled}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default Question;
export const QUESTIONS_PAGE = "/play";
