import React, { Component } from "react";
import { app } from "../service/firebase";
import { Card, Space, Typography } from "antd";
import { LineChart, Label, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import "../styles/App.css";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import TweenOne from "rc-tween-one";
import Children from "rc-tween-one/lib/plugin/ChildrenPlugin";
import incorrect from "../images/red.svg";
import correct from "../images/green.svg";
const { Title } = Typography;

const db = app.firestore();
let summary;
let numberOfQuestionsAnswered;
let numberOfCorrectAnswers = 0;
let statisticsAdded = false;
let engagementDBKey = "engagement";

var moment = require("moment-timezone");
var genesis = moment("08/15/2022");

const correctBorder = {
  border: '1px solid rgba(0, 255, 26, 0.4)', 
  marginTop: "35vh"
};

const incorrectBorder = {
  border: '1px solid rgba(  255, 0, 0, 0.5)', 
  marginTop: "35vh"
};


TweenOne.plugins.push(Children);

class End extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitedFeedback: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      score: null,
      animation: null,
      highestToday: 0,
      lowestToday: 0,
      averageToday: 0,
      highestScoreOfAllTime: 0,
    };
  }

  handleClick() {
    window.location.href = "/play";
  }

  parseSummaryData(data) {
    let summary = [];
    let dataArray = data.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
    for (let i = 0; i < dataArray.length; i += 8) {
      if (dataArray[i + 2] >= 1000000000000) {
        dataArray[i + 2] /= 1000000000000;
        dataArray[i + 2] =
          parseFloat(dataArray[i + 2]).toFixed(1) + " trillion";
      }
      if (dataArray[i + 3] >= 1000000000000) {
        dataArray[i + 3] /= 1000000000000;
        dataArray[i + 3].toFixed(2);
        dataArray[i + 3] =
          parseFloat(dataArray[i + 3]).toFixed(1) + " trillion";
      }
      if (dataArray[i + 2] >= 1000000000) {
        dataArray[i + 2] /= 1000000000;
        dataArray[i + 2] = parseFloat(dataArray[i + 2]).toFixed(1) + " billion";
      }
      if (dataArray[i + 3] >= 1000000000) {
        dataArray[i + 3] /= 1000000000;
        dataArray[i + 3] = parseFloat(dataArray[i + 3]).toFixed(1) + " billion";
      }
      if (dataArray[i + 2] >= 1000000) {
        dataArray[i + 2] /= 1000000;
        dataArray[i + 2] = parseFloat(dataArray[i + 2]).toFixed(1) + " million";
      }
      if (dataArray[i + 3] >= 1000000) {
        dataArray[i + 3] /= 1000000;
        dataArray[i + 3] = parseFloat(dataArray[i + 3]).toFixed(1) + " million";
      }
      summary.push({
        key: dataArray[i],
        question: dataArray[i + 1].replace(/"([^"]+(?="))"/g, "$1"),
        lower: dataArray[i + 2],
        upper: dataArray[i + 3],
        answer: dataArray[i + 4],
        score: parseFloat(dataArray[i + 5]).toFixed(0),
        correct: dataArray[i + 6],
        link: dataArray[i + 7].replace(/"([^"]+(?="))"/g, "$1"),
      });
    }
    this.state.score = this.calculateScore(summary);
    if (!statisticsAdded) {
      let PT = moment().tz("America/Los_Angeles");
      var days = PT.diff(genesis, "days") + 1;
      if (JSON.parse(localStorage.getItem("day")) !== days) {
        window.location.href = "/";
      }
      if (JSON.parse(localStorage.getItem("status")) === "in_progress") {
        this.addUserEngagement(this.state.score);
        this.addUserData(dataArray);
      } else {
        this.setTodaysStats();
        this.setScoresOverTime();
      }
      statisticsAdded = true;
    }
    return summary;
  }

  addUserData = async (data) => {
    try {
      this.parseDataForProfile(data);
      let dataFromStorage = JSON.parse(localStorage.getItem("statistics"));
      let statistics = new Object();
      if (dataFromStorage !== null) {
        let newLowestScore;
        let newHighestScore;
        let newNumberOfGames = dataFromStorage.numberOfGames + 1;
        let newTotalScore = dataFromStorage.totalPoints + this.state.score;
        let newNumberOfCorrectAnswers =
          numberOfCorrectAnswers + dataFromStorage.numberOfCorrectAnswers;
        let percentAccurateForThisGame =
          (numberOfCorrectAnswers / numberOfQuestionsAnswered) * 100;
        let answered =
          dataFromStorage.questionAnswered + numberOfQuestionsAnswered;
        let weightForThisGame = numberOfQuestionsAnswered / answered;
        let weightedPercentAverage =
          percentAccurateForThisGame * weightForThisGame +
          dataFromStorage.percentAccurate * (1 - weightForThisGame);
        let newAveragePointsPerGame =
          dataFromStorage.averagePoints * (1 - weightForThisGame) +
          this.state.score * weightForThisGame;
        if (dataFromStorage.lowestScore > this.state.score) {
          newLowestScore = this.state.score;
        } else {
          newLowestScore = dataFromStorage.lowestScore;
        }
        if (dataFromStorage.highestScore < this.state.score) {
          newHighestScore = this.state.score;
        } else {
          newHighestScore = dataFromStorage.highestScore;
        }
        statistics.questionAnswered = answered;
        statistics.lowestScore = newLowestScore;
        statistics.highestScore = newHighestScore;
        statistics.totalPoints = newTotalScore;
        statistics.averagePoints = newAveragePointsPerGame;
        statistics.percentAccurate = weightedPercentAverage;
        statistics.numberOfGames = newNumberOfGames;
        statistics.numberOfCorrectAnswers = newNumberOfCorrectAnswers;
        statistics.lastDayPlayed = JSON.parse(localStorage.getItem("day"));
        localStorage.setItem("statistics", JSON.stringify(statistics));
      } else {
        let answered = numberOfQuestionsAnswered;
        let percentAccurate =
          (numberOfCorrectAnswers / numberOfQuestionsAnswered) * 100;
        statistics.questionAnswered = answered;
        statistics.lowestScore = this.state.score;
        statistics.highestScore = this.state.score;
        statistics.totalPoints = this.state.score;
        statistics.averagePoints = this.state.score;
        statistics.percentAccurate = percentAccurate;
        statistics.numberOfGames = 1;
        statistics.numberOfCorrectAnswers = numberOfCorrectAnswers;
        statistics.lastDayPlayed = JSON.parse(localStorage.getItem("day"));
        localStorage.setItem("statistics", JSON.stringify(statistics));
      }
    } catch (err) {
      console.error(err);
    }
    localStorage.setItem("status", JSON.stringify("complete"));
  };

  addScoresOverTime = async (average) => {
    let scoresOverTime = JSON.parse(localStorage.getItem("scoresOverTime"));
    let statistics = JSON.parse(localStorage.getItem("statistics"));
    let overTime = new Object();
    overTime.score = this.state.score;
    overTime.calibration = statistics.percentAccurate;
    overTime.day = localStorage.getItem("day");
    overTime.average = average;
    let overTimeArr = [];
    if (scoresOverTime !== null) {
      overTimeArr = scoresOverTime;
      overTimeArr.push(overTime);
      localStorage.setItem("scoresOverTime", JSON.stringify(overTimeArr));
      await this.setYesterdaysAverage();
    } else {
      overTimeArr.push(overTime);
      localStorage.setItem("scoresOverTime", JSON.stringify(overTimeArr));
    }
    if (overTimeArr.length > 29) {
      overTimeArr = overTimeArr.slice(
        overTimeArr.length - 30,
        overTimeArr.length
      );
    }
    this.setState({ dataOverTime: overTimeArr });
  };

  setScoresOverTime = () => {
    let scoresOverTime = JSON.parse(localStorage.getItem("scoresOverTime"));
    if (scoresOverTime.length > 29) {
      scoresOverTime = scoresOverTime.slice(
        scoresOverTime.length - 30,
        scoresOverTime.length
      );
    }
    this.setState({ dataOverTime: scoresOverTime });
  };

  setYesterdaysAverage = async () => {
    let overTime = new Object();
    overTime = JSON.parse(localStorage.getItem("scoresOverTime"));
    let data = await this.getDataFromDatabase(
      engagementDBKey,
      overTime[overTime.length - 2].day
    );
    overTime[overTime.length - 2].average = data.average;
    localStorage.setItem("scoresOverTime", JSON.stringify(overTime));
  };

  setTodaysStats = async () => {
    let data = await this.getDataFromDatabase(engagementDBKey, undefined);
    let highestOAT = await this.getHighestOfAllTimeFromDatabase();
    this.setState({ highestToday: data.highest });
    this.setState({ lowestToday: data.lowest });
    this.setState({ averageToday: data.average });
    this.setState({ highestScoreOfAllTime: highestOAT.highest });
  };

  addUserEngagement = async (score) => {
    let data = await this.getDataFromDatabase(engagementDBKey, undefined);
    let highestOAT = await this.getHighestOfAllTimeFromDatabase();
    this.setState({ highestToday: data.highest });
    this.setState({ lowestToday: data.lowest });
    this.setState({ averageToday: data.average });
    this.setState({ highestScoreOfAllTime: highestOAT.highest });
    if (data.lowest === undefined || data.lowest > score) {
      this.setState({ lowestToday: score });
      await this.setLowestScore(score);
    }
    if (data.highest === undefined || data.highest < score) {
      this.setState({ highestToday: score });
      await this.setHighestScore(score);
    }
    if (highestOAT.highest === undefined || highestOAT.highest < score) {
      this.setState({ highestScoreOfAllTime: score });
      await this.setHighestOfAllTimeScore(score);
    }
    if (data.average === undefined) {
      this.setAverageScore(score);
    } else {
      await this.setAverageScore(
        this.calculateAverage(data.completions, data.average, score)
      );
    }
    this.addScoresOverTime(this.state.averageToday);
  };

  calculateAverage(count, average, score) {
    return (average * (count - 1) + score) / count;
  }

  setAverageScore = async (score) => {
    this.setState({ averageToday: score });
    let day = localStorage.getItem("day");
    await db.collection(engagementDBKey).doc(day).update({
      average: score,
    });
  };

  setLowestScore = async (score) => {
    let day = localStorage.getItem("day");
    await db.collection(engagementDBKey).doc(day).update({
      lowest: score,
    });
  };

  setHighestScore = async (score) => {
    let day = localStorage.getItem("day");
    await db.collection(engagementDBKey).doc(day).update({
      highest: score,
    });
  };

  setHighestOfAllTimeScore = async (score) => {
    await db.collection(engagementDBKey).doc("highestOfAllTime").update({
      highest: score,
    });
  };

  getDataFromDatabase = async (collection, passedDay) => {
    let dataFromFirestore;
    let day = passedDay ? passedDay : localStorage.getItem("day");
    var docRef = db.collection(collection).doc(day);
    await docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          dataFromFirestore = doc.data();
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
    return dataFromFirestore;
  };

  getHighestOfAllTimeFromDatabase = async () => {
    let dataFromFirestore;
    var docRef = db.collection(engagementDBKey).doc("highestOfAllTime");
    await docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          dataFromFirestore = doc.data();
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
    return dataFromFirestore;
  };

  parseDataForProfile(data) {
    numberOfQuestionsAnswered = data.length / 8;
    let count = 1;
    for (let i = 0; i < data.length; i++) {
      if (i === 0) {
        numberOfCorrectAnswers = 0;
      }
      if (count === 7) {
        if (data[i] === "1") {
          numberOfCorrectAnswers++;
          count = 0;
          continue;
        } else if (data[i] === "0") {
          count = 0;
          continue;
        }
      }
      count++;
    }
  }

  calculateScore(summary) {
    let tempScore = 0;
    for (let i = 0; i < summary.length; i++) {
      tempScore += parseFloat(summary[i].score);
    }
    return tempScore;
  }

  animate() {
    this.setState({
      animation: {
        Children: {
          value: typeof value === "number" ? 0 : this.state.score,
          floatLength: 0,
        },
        duration: 500,
      },
    });
  }

  render() {
    summary = this.parseSummaryData(localStorage.getItem("summary"));
    if (this.state.score && this.state.animation === null) {
      this.animate();
    }
    localStorage.removeItem("stats");
    let statistics = JSON.parse(localStorage.getItem("statistics"));
    let references = localStorage
      .getItem("references")
      .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

    return (
      <>
        <main className="scroll-container">
          <section style={{ position: "relative" }} className="score-page">
            <Space
              direction="vertical"
              size="middle"
              style={{
                display: "flex",
              }}
            >
              <div style={{ position: "relative", backgroundColor: "#292929" }}>
                <Card
                  bordered={false}
                  title={
                    <Title level={3} style={{ fontWeight: "400" }}>
                      Your Statistics
                    </Title>
                  }
                  style={{
                    backgroundColor: "#1f1f1f",
                    fontWeight: "bolder",
                  }}
                >
                  <Space
                    direction="vertical"
                    size="middle"
                    align="center"
                    style={{
                      display: "flex",
                    }}
                  >
                    <Card bordered={false} className="your-score-card">
                      {this.state.score > 0 ? (
                        <>
                          <TweenOne
                            animation={this.state.animation}
                            style={{
                              fontSize: 35,
                            }}
                            className="your-score-number"
                          ></TweenOne>
                        </>
                      ) : (
                        <h2 className="your-score-number">0</h2>
                      )}
                      <p className="your-score-text">Today</p>
                    </Card>
                    {statistics.averagePoints ? (
                      <Space>
                        <Card
                          bordered={false}
                          size="small"
                          className="your-statistics-cards"
                        >
                          <p className="your-statistics-number">
                            {statistics.averagePoints.toFixed(0)}
                          </p>
                          <p className="your-statistics-text">Average</p>
                        </Card>
                        <Card
                          bordered={false}
                          size="small"
                          className="your-statistics-cards"
                        >
                          <p className="your-statistics-number">
                            {statistics.percentAccurate.toFixed(0)}%
                          </p>
                          <p className="your-statistics-text">Calibration</p>
                        </Card>
                        <Card
                          bordered={false}
                          size="small"
                          className="your-statistics-cards"
                        >
                          <p className="your-statistics-number">
                            {statistics.highestScore.toFixed(0)}
                          </p>
                          <p className="your-statistics-text">High</p>
                        </Card>
                      </Space>
                    ) : (
                      <Space>
                        <Card
                          bordered={false}
                          size="small"
                          className="your-statistics-cards"
                        >
                          <p className="your-statistics-number">0</p>
                          <p className="your-statistics-text">Average</p>
                        </Card>
                        <Card
                          bordered={false}
                          size="small"
                          className="your-statistics-cards"
                        >
                          <p className="your-statistics-number">0%</p>
                          <p className="your-statistics-text">Calibration</p>
                        </Card>
                        <Card
                          bordered={false}
                          size="small"
                          className="your-statistics-cards"
                        >
                          <p className="your-statistics-number">0</p>
                          <p className="your-statistics-text">High Score</p>
                        </Card>
                      </Space>
                    )}
                  </Space>
                </Card>
              </div>
              <Card
                title={
                  <Title level={4} style={{ fontWeight: "200" }}>
                    Today's Scores
                  </Title>
                }
                bordered={false}
                style={{
                  backgroundColor: "#1f1f1f",
                  fontWeight: "bolder",
                }}
              >
                {this.state.averageToday ? (
                  <Space>
                    <Card
                      bordered={false}
                      size="small"
                      className="today-statistics-cards"
                    >
                      <p className="today-statistics-number">
                        {this.state.lowestToday.toFixed(0)}
                      </p>
                      <p className="today-statistics-text">Low</p>
                    </Card>
                    <Card
                      bordered={false}
                      size="small"
                      className="today-statistics-cards"
                    >
                      <p className="today-statistics-number">
                        {this.state.averageToday.toFixed(0)}
                      </p>
                      <p className="today-statistics-text">Average</p>
                    </Card>
                    <Card
                      bordered={false}
                      size="small"
                      className="today-statistics-cards"
                    >
                      <p className="today-statistics-number">
                        {this.state.highestToday.toFixed(0)}
                      </p>
                      <p className="today-statistics-text">High</p>
                    </Card>
                  </Space>
                ) : (
                  <Space>
                    <Card
                      bordered={false}
                      size="small"
                      className="today-statistics-cards"
                    >
                      <p className="today-statistics-number">0</p>
                      <p className="today-statistics-text">Low</p>
                    </Card>
                    <Card
                      bordered={false}
                      size="small"
                      className="today-statistics-cards"
                    >
                      <p className="today-statistics-number">0</p>
                      <p className="today-statistics-text">Average</p>
                    </Card>
                    <Card
                      bordered={false}
                      size="small"
                      className="today-statistics-cards"
                    >
                      <p className="today-statistics-number">0</p>
                      <p className="today-statistics-text">High</p>
                    </Card>
                  </Space>
                )}
              </Card>
            </Space>
          </section>
          {this.state.dataOverTime && this.state.dataOverTime.length > 2 ? (
            <section style={{ position: "relative" }}>
              <Space
                direction="vertical"
                size="middle"
                style={{
                  display: "flex",
                }}
              >
                <Card
                  bordered={false}
                  className="line-chart-card"
                  style={{
                    fontWeight: "bolder",
                  }}
                >
                  <p style={{textAlign: "left", fontSize: "18px"}}>Last 30 plays</p>
                  <ResponsiveContainer width="99%" aspect={1.1}>
                  <LineChart
                    data={this.state.dataOverTime}
                    className="line-chart"
                  >
                    <XAxis dataKey="Score" stroke="rgb(255, 255, 255, 0.925)">
                      <Label
                        offset={3}
                        position="insideBottom"
                        fill={"white"}
                      />
                    </XAxis>
                    <YAxis width={35} scale="linear" stroke="rgb(255, 255, 255, 0.925)" />
                    <Tooltip  contentStyle={{backgroundColor: "#121212"}} formatter={(value) => value && value.toFixed(0)} />
                    <Line type="monotone" dataKey="score" dot={false} stroke="#3dd2ff" />
                    <Line
                      type="monotone"
                      dataKey="average"
                      stroke="#59ff00"
                      dot={false}
                    />
                  </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Space>
            </section>
          ) : null}
          <section style={{ position: "relative" }}>
            <h2
              className="answer"
              style={{
                paddingBottom: "35vh",
                position: "absolute",
                zIndex: "100",
              }}
            >
              <p style={{ padding: "2%" }}>{summary[0].question}</p>
              <Card size="small" className="correct-answer-card">
                <p className="correct-answer-number">
                  {summary[0].answer
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                <p className="correct-answer-text">Correct Answer</p>
              </Card>
            </h2>
            {summary[0].correct === "1" ? (
              <img
                className="answer-bar"
                style={{ position: "absolute" }}
                src={correct}
              />
            ) : (
              <img
                className="answer-bar"
                style={{ position: "absolute" }}
                src={incorrect}
              />
            )}
            <Card
              size="small"
              className="score-card"
              style={summary[0].correct === "1" ? correctBorder: incorrectBorder}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginTop: "1em",
                  marginBottom: "0",
                }}
              >
                {summary[0].score}
              </p>
              <p>Score</p>
            </Card>
            <div
              style={{
                width: "68%",
                justifyContent: "center",
                position: "absolute",
              }}
            >
              <h3
                className="user-guess"
                style={{ paddingTop: "10vh", float: "left" }}
              >
                {summary[0].lower
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h3>
              <h3
                className="user-guess"
                style={{ paddingTop: "10vh", float: "right" }}
              >
                {summary[0].upper
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h3>
            </div>
          </section>
          <section style={{ position: "relative" }}>
            <h2
              className="answer"
              style={{
                paddingBottom: "35vh",
                position: "absolute",
                zIndex: "100",
              }}
            >
              <p style={{ padding: "2%" }}>{summary[1].question}</p>
              <Card size="small" className="correct-answer-card">
                <p className="correct-answer-number">
                  {summary[1].answer
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                <p className="correct-answer-text">Correct Answer</p>
              </Card>
            </h2>
            {summary[1].correct === "1" ? (
              <img
                className="answer-bar"
                style={{ position: "absolute" }}
                src={correct}
              />
            ) : (
              <img
                className="answer-bar"
                style={{ position: "absolute" }}
                src={incorrect}
              />
            )}
            <Card
              size="small"
              className="score-card"
              style={summary[1].correct === "1" ? correctBorder: incorrectBorder}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginTop: "1em",
                  marginBottom: "0",
                }}
              >
                {summary[1].score}
              </p>
              <p>Score</p>
            </Card>
            <div
              style={{
                width: "68%",
                justifyContent: "center",
                position: "absolute",
              }}
            >
              <h3
                className="user-guess"
                style={{ paddingTop: "10vh", float: "left" }}
              >
                {summary[1].lower
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h3>
              <h3
                className="user-guess"
                style={{ paddingTop: "10vh", float: "right" }}
              >
                {summary[1].upper
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h3>
            </div>
          </section>
          <section style={{ position: "relative" }}>
            <h2
              className="answer"
              style={{
                paddingBottom: "35vh",
                position: "absolute",
                zIndex: "100",
              }}
            >
              <p style={{ padding: "2%" }}>{summary[2].question}</p>
              <Card size="small" className="correct-answer-card">
                <p className="correct-answer-number">
                  {summary[2].answer
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                <p className="correct-answer-text">Correct Answer</p>
              </Card>
            </h2>
            {summary[2].correct === "1" ? (
              <img
                className="answer-bar"
                style={{ position: "absolute" }}
                src={correct}
              />
            ) : (
              <img
                className="answer-bar"
                style={{ position: "absolute" }}
                src={incorrect}
              />
            )}
            <Card
              size="small"
              className="score-card"
              style={summary[2].correct === "1" ? correctBorder: incorrectBorder}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginTop: "1em",
                  marginBottom: "0",
                }}
              >
                {summary[2].score}
              </p>
              <p>Score</p>
            </Card>
            <div
              style={{
                width: "68%",
                justifyContent: "center",
                position: "absolute",
              }}
            >
              <h3
                className="user-guess"
                style={{ paddingTop: "10vh", float: "left" }}
              >
                {summary[2].lower
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h3>
              <h3
                className="user-guess"
                style={{ paddingTop: "10vh", float: "right" }}
              >
                {summary[2].upper
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h3>
            </div>
          </section>
          <section style={{ position: "relative" }}>
            <Card
              bordered={false}
              className="further-reading-card"
              style={{
                backgroundColor: "#080808",
                fontWeight: "bolder",
              }}
            >
              <Title level={4} className="further-reading-text">
                Further Reading
              </Title>
              <div className="further-reading-links">
                <Space direction="vertical">
                  <div>
                    1.{" "}
                    <a
                      href={summary[0].link}
                      className="hyperlink"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {references[0].replace(/"([^"]+(?="))"/g, "$1")}
                    </a>
                  </div>
                  <div className="further-reading-answer">
                    {summary[0].answer
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </div>
                </Space>
              </div>
              <div className="further-reading-links">
                <Space direction="vertical">
                  <div>
                    2.{" "}
                    <a
                      href={summary[1].link}
                      className="hyperlink"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {references[1].replace(/"([^"]+(?="))"/g, "$1")}
                    </a>
                  </div>
                  <div className="further-reading-answer">
                    {summary[1].answer
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </div>
                </Space>
              </div>
              <div className="further-reading-links">
                <Space direction="vertical">
                  <div>
                    3.{" "}
                    <a
                      href={summary[2].link}
                      className="hyperlink"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {references[2].replace(/"([^"]+(?="))"/g, "$1")}
                    </a>
                  </div>
                  <div className="further-reading-answer">
                    {summary[2].answer
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </div>
                </Space>
              </div>
            </Card>
          </section>
        </main>
      </>
    );
  }
}

export default End;
export const GAME_OVER_PAGE = "/gameover";
