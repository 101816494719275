import React, { Component } from "react";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

let dataFromStorage;
class Profile extends Component {
  componentDidMount() {
    this.getUserData();
  }
  getUserData = async () => {
    if (localStorage.getItem("statistics")) {
      dataFromStorage = JSON.parse(localStorage.getItem("statistics"));
      this.forceUpdate();
    }
  };

  render() {
    return (
      <div>
        {dataFromStorage !== undefined ? (
          <>
            <p>Total Games Played: {dataFromStorage.numberOfGames}</p>
            <p>Total Points: {dataFromStorage.totalPoints.toFixed(2)}</p>
            <p>
              Total Answers in Interval:{" "}
              {dataFromStorage.numberOfCorrectAnswers}
            </p>
            <p>
              Total Questions Answered:{" "}
              {dataFromStorage.questionAnswered}
            </p>
            <p>
              Confidence Calibration: {dataFromStorage.numberOfCorrectAnswers}/{dataFromStorage.questionAnswered} = {dataFromStorage.percentAccurate.toFixed(2)}%
            </p>
            <p>
              Average Points/Game:{" "}
              {dataFromStorage.averagePoints.toFixed(2)}
            </p>
            <p>Highest Day Score: {dataFromStorage.highestScore.toFixed(2)}</p>
            <p>Lowest Day Score: {dataFromStorage.lowestScore.toFixed(2)}</p>
           
           
          </>
        ) : <p>No data yet</p>}
      </div>
    );
  }
}

export default Profile;
export const PROFILE_PAGE = "/profile";
