import dotenv from 'dotenv'
import "firebase/auth"
import firebase from 'firebase/app'
dotenv.config()

firebase.initializeApp({
    apiKey: "AIzaSyAYX3cIH4NHqMeMsEhPqQJS5mAZNB6fx7A",
    authDomain: "percent-confident.firebaseapp.com",
    projectId: "percent-confident",
    storageBucket: "percent-confident.appspot.com",
    messagingSenderId: "896425205617",
    appId: "1:896425205617:web:ea39c810205f8ac49e60d0",
    measurementId: "G-KHG2WSBM1B",
});

export const app = !firebase.apps.length
  ? firebase.initializeApp()
  : firebase.app();