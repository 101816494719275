import React from "react";
import Question, { QUESTIONS_PAGE } from "../components/Question.jsx";
import Scoring, { SCORING_PAGE } from "../components/Scoring.jsx";
import End, { GAME_OVER_PAGE } from "../components/End.jsx";
import Profile, {PROFILE_PAGE} from "../components/Profile.jsx";

export default class UrlTranslator {
  static getPageFromUrl(url, props) {
    switch (url) {
      case QUESTIONS_PAGE:
        return { page: <Question {...props} /> };
      case SCORING_PAGE:
        return { page: <Scoring {...props} /> };
      case GAME_OVER_PAGE:
        return { page: <End {...props} /> };
      case PROFILE_PAGE:
        return { page: <Profile {...props} /> };  
      default:
        return { page: <Question {...props} /> };
    }
  }
}
