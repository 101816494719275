import React, { Component } from "react";

class Scoring extends Component {
  render() {
    return (
      <div className="App">
        <div className="Scoring" style={{ padding: "5%", fontSize: "14px" }}>
          <p>
            <p>
              Daily Quant Game | 3 Questions | Answers are 95% Confidence
              Intervals
            </p>
            <div style={{fontSize: "12px", textAlign: "left"}}>
            <p>
              <em style={{fontSize: "14px"}}>Purpose</em> of 4-σ is to estimate the numerical quantity of
              various things.
            </p>
            <p>
              <em style={{fontSize: "14px"}}>Answers</em> are given with two numbers, an Upper Bound and a
              Lower Bound, such that you are 95% sure the true answer lies
              somewhere between the bounds you gave.
            </p>
            <p>
              <em style={{fontSize: "14px"}}> Scoring</em>  works as follows: If the upper and lower bound you submit contains the true answer, you will get more points the narrower your range. However, if the true answer is outside the bound you gave, you will get no points for that question.
            </p>
            <p>
              <em style={{fontSize: "14px"}}>Question topics</em> range from numeric trivia to some of the most important stories of modern times.
            </p>
            <p>
              <em style={{fontSize: "14px"}}>Calibration</em> score refers to the percent of questions you
              have answered for which your range did contain the true answer.
              For example, if you have answered 12 questions, and 6 intervals
              were wide enough to include the correct answer your calibration
              would be 50%. The reason the statistic is called a “calibration”
              is that the number shows how well-calibrated you are to your own
              certainty. If you are perfectly calibrated (no one is), your 95%
              confidence interval calibration should be 95%. The greater the
              disparity between your calibration and the 95% goal, the more
              overconfident you are.
            </p>
            </div>
          </p>
        </div>
      </div>
    );
  }
}

export default Scoring;
export const SCORING_PAGE = "/scoring";
